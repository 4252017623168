
import images from "./widget/images"
export default
    {
        "type": "object",
        "properties": {
            "title": {
                "type": "string",
                "ui": {
                    "label": "title"
                }
            },

            "thumb": images({}),

        }
    }